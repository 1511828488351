import React from 'react'

import {Helmet} from "react-helmet"

import Header from '../components/oneTimeComponents/AlertHeader'
import NavBar from '../components/oneTimeComponents/NavigationBar'
import Footer from '../components/oneTimeComponents/JumbotronFooter'

export default function pagina404(){

    const fontStyle ={ fontSize:"50px",
                        marginTop:"50px",
                        marginBottom:"50px",
                        textAlign: "center"
                    }

    return(
    <div className="container">
        <Helmet>
                <title>Error : 404</title>
        </Helmet>
        <NavBar page="EN" sezione="0"/>
        <div className="alert alert-danger">
                <p style={fontStyle}>
            Error 404:Page not Found</p>
            
        </div>
        <Footer/>
    </div>
)
}